@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    color: #870016;
    text-decoration: none;
    cursor: pointer;
}

h1 {
    font-size: 2rem;
    font-weight: bold;
}

.header-nav {
    position: fixed;
    z-index: 1;
    width: 100vw;
}

.header-container {
    background-color: black;
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}

.header-container a {
    justify-self: end;
    padding-right: 2rem;
}

.menu-icon {
    height: 60%;
    width: auto;
}

.nav-container {
    background-color: white;
    color: white;
    font-size: 18px;
}

.nav-options {
    background-color: black;
    display: grid;
    gap: 1rem;
    grid-template-rows: repeat(1fr);
    padding: 0.5rem 2rem;
}

.nav-productos span {
    float: right;
    color: #870016;
    font-size: 18px;
    font-weight: bold;
}

.product-clasification {
    margin: 0.5rem 2rem;
}

.product-clasification li {
    margin: 0.5rem 0;
}

.socials {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.socials img {
    height: 60%;
}

hr {
    background-image: linear-gradient(180deg, gray, transparent);

    border: 0;
    height: 1px;
}

.footer-container {
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 5rem 0;
    margin-top: 5rem;
}

.footer-container p,
span {
    color: rgb(162, 167, 171);
}

.text-logo-footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 1rem;
}

.text-logo-footer img {
    width: 60%;
}

.socials-footer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.socials-footer img {
    height: 1.5rem;
    width: auto;
}

.contacto-footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 1rem;
}

.contacto-footer div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.contacto-footer img {
    height: 1.25rem;
}

.presupuesto-footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem;
}

.presupuesto-footer a {
    align-self: center;
}

.presupuesto-footer button {
    align-self: center;
    margin-top: 0.5rem;
}

.cta-default-btn {
    cursor: pointer;
    padding: 1rem 1.5rem;
    border: white solid 1px;
    color: white;
    background-color: #870016;
    border-radius: 0.5rem;
    font-size: 16px;
}

.cta-default-btn:hover {
    filter: brightness(125%);
}

.logo-title {
    color: #eb0000;
    font-size: 26px;
    text-align: center;
}

.inicio-container {
    display: flex;
    flex-direction: column;
}

.bienvenida {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    margin-top: 5rem;
    background-color: ghostwhite;
    background-position: center;
    background-size: auto;
    color: white;
    font-weight: bold;
    height: 50vh;
}

.bienvenida-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 1rem 1rem 1rem;
    height: 100%;
}

.bienvenida-header div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    backdrop-filter: blur(10px);

    border-radius: 10px;
    padding: 1rem;
    font-weight: normal;
    font-size: 20px;
}

.bienvenida p {
    font-size: 18px;
}

.example-products {
    padding: 2rem 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
}

.example-products-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.example-products-header h1 {
    margin: auto;
}

.example-products-header div:first-child {
    display: flex;
    flex-direction: column;
    background-color: #000000;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 1rem 0;
    width: 100%;
    text-align: center;
}

.example-products-header-underline {
    background-image: linear-gradient(180deg, #eb0000, transparent);
    border: 0;
    height: 3px;
    max-width: 300px;
    width: 100%;
}

.product-image {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.product-image img {
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 100%;
}

.trabajos-container {
    padding-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.nuestros-trabajos-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    max-width: 1200px;
}

.trabajos-container h2 {
    background-color: black;
    color: white;
    width: 100%;
    text-align: center;
    padding: 2rem 0;
}

.trabajos-container hr {
    background-image: linear-gradient(180deg, #eb0000, transparent);
    border: 0;
    height: 3px;
    width: 150px;
    margin: auto;
}

.products-nav {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    margin: auto;
}

.products-nav li {
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 0.5rem;
}

.products-nav li:hover {
    color: white;
    background-color: #eb0000;
}

.active {
    color: white;
    background-color: #eb0000;
}

.active a:hover {
    /* override default a:hover when on .active class */
    color: white;
}

.in-active {
    border: 1px solid lightgray;
}

.products-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    padding: 1rem;
}

.products-items div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.products-items img {
    border-radius: 3px;
    flex: 1;
    margin: auto;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: fill;
}

.products-items span {
    flex: 1;
    text-align: center;
    color: black;
}

.presupuesto-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding-top: 5.5rem;
}

.presupuesto-container h1 {
    text-align: center;
    margin: 1rem;
}

.presupuesto-container p {
    padding: 1rem;
}

.presupuesto-container hr {
    background-image: linear-gradient(180deg, #eb0000, transparent);
    border: 0;
    height: 3px;
    width: 150px;
    margin: auto;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
    width: 80%;
}

.form-container label {
    width: 100%;
}

.form-container input {
    border: 0.5px solid rgb(151, 151, 151);
    background-color: rgb(255, 255, 255);
    padding: 12px 0px 12px 6px;
    width: 100%;
    border-radius: 5px;
}

.form-container input:focus {
    outline: 0.5px solid #eb0000;
}

.form-container textarea {
    border: 0.5px solid rgb(151, 151, 151);
    padding: 12px 0px 12px 6px;
    width: 100%;
    height: 6rem;
    resize: none;
    border-radius: 5px;
}

.form-container textarea:focus {
    outline: 0.5px solid #eb0000;
}

.consentimiento {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
}

.consentimiento input {
    padding: auto;
    width: auto;
    margin-top: 4px;
}

.comentarios-obs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.productos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5rem;
}

.productos-container h1 {
    text-align: center;
    padding: 1rem;
}

.productos-container p {
    align-self: start;
    padding: 1rem;
}

.productos-container hr,
.productos-categoria hr {
    background-image: linear-gradient(180deg, #eb0000, transparent);
    border: 0;
    height: 3px;
    width: 100px;
    margin: auto;
}

.product-categories {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.product-category-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    margin: 1rem 1rem 2rem 1rem;
    padding-bottom: 1rem;
    border-radius: 1rem;
    font-size: 20px;
    box-shadow: 2px 2px 10px -5px rgb(0, 0, 0);
}

/* .product-category-item:hover {
    cursor: pointer;
    background-color: rgba(235, 0, 0, 0.9);
    border: solid 2px white;
    color: white;
} */

.product-category-item-header {
    padding: 1rem;
    background-color: #a1001b;
    color: white;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 6rem;
}

.product-category-item h3 {
    display: block;
    text-align: center;
    padding: 0.5rem 0;
}

.product-category-item p {
    display: block;
    text-align: left;
    font-size: 16px;
}

.product-category-item-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: white;
    border-top-right-radius: 1.5rem;
    flex: 1;
    justify-content: space-between;
}

.product-category-item img {
    border-radius: 0.5rem;
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 100%;
    margin: auto;
    border: 2px solid white;
    border-radius: 1rem;
}

.productos-categoria {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5.5rem;
}

.productos-categoria-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(43, 43, 43);
    width: 100%;
    padding: 1rem 0;
}

.productos-categoria-title h1 {
    align-self: center;
    padding: 1rem;
}

.productos-categoria-title hr {
    max-width: 1000px;
    width: 100%;
}

.productos-categoria-description {
    padding: 1rem;
    max-width: 990px;
}

.producto-por-categoria {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    padding: 1rem;
}

.producto-por-categoria-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.producto-por-categoria-item h3 {
    text-align: center;
    align-self: center;
    flex: 1;
}

.producto-por-categoria-item img {
    border-radius: 2px;
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 100%;
    margin: auto;
    flex: 1;
}

.producto-categoria-btns {
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.header-nav-desktop,
.nav-container-desktop,
.sticky {
    display: none;
}

@media screen and (max-width: 479px) {
    .example-products {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .product-categories {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media screen and (min-width: 479px) {
    .example-products {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .product-categories {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .producto-por-categoria {
        gap: 2rem;
    }
    .producto-por-categoria-item img {
        max-width: 480px;
    }
}

/* Desktop screen */
@media screen and (min-width: 961px) {
    .header-nav,
    .nav-container {
        display: none;
    }

    .header-nav-desktop,
    .nav-container-desktop,
    .sticky {
        display: block;
    }

    .bienvenida {
        margin-top: 0;
        height: 70vh;
        background-size: cover;
    }

    .main-header-desktop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 2rem;
    }

    .header-title-desktop {
        margin-bottom: 10px;
        max-width: 500px;
    }

    .header-btton-div-desktop {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding: 0.5rem;
        cursor: pointer;
    }

    .header-btton-div-desktop button {
        background-color: white;
        border: none;
        font-size: 18px;
        font-size: bold;
        cursor: pointer;
    }

    .header-btton-div-desktop button:hover {
        color: #870016;
    }

    .nav-container-desktop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #000000;
        color: white;
        padding: 1.5rem 3rem;
    }

    .sticky {
        position: fixed;
        top: 0;
        width: calc(100vw);
        z-index: 1;
    }

    .nav-container-desktop li {
        list-style-type: none;
    }

    .nav-links-desktop {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2.5rem;
        font-size: 20px;
    }

    .socials-links-desktop {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    .socials-links-desktop img {
        height: 30px;
        cursor: pointer;
    }

    .product-clasification-desktop {
        display: none;
    }

    .productos-nav-element-desktop :hover + .product-clasification-desktop,
    .product-clasification-desktop:hover {
        display: block;
        position: absolute;
        padding: 28px 1.5rem 1rem 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        background-color: #000000;
        z-index: 1;
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        margin-top: -4px;
    }

    .footer-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .footer-container div {
        justify-self: center;
        padding: 0.5rem;
    }

    .footer-container hr {
        grid-column: span 3;
    }

    .example-products {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }

    .productos-container {
        padding-top: 1rem;
    }

    .productos-container p {
        font-size: 20px;
    }

    .product-categories {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }

    .productos-categoria {
        padding-top: 1rem;
    }

    .products-items {
        grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
        max-width: 1200px;
        gap: 2rem;
    }

    .products-items div * {
        max-width: 480px;
    }

    .trabajos-container {
        padding-top: 1rem;
    }

    .presupuesto-container {
        margin: auto;
        max-width: 1000px;
        padding-top: 1rem;
    }
    .producto-por-categoria {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        max-width: 990px;
        gap: 2rem;
    }
    .producto-por-categoria-item img {
        max-width: 480px;
    }
}
